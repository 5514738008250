import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styles: [
  ]
})
export class MenubarComponent implements OnInit {

  items: MenuItem[] = [];

  constructor(private auth: AuthService) {
    for (const page of this.auth.user.permisos) {
      var item = {
        label: page.nombre,
        icon: page.icono,
        routerLink: page.endpoint,
        tabindex: (page.orden).toString()
      };
      this.items.push(item);
    }
  }

  ngOnInit(): void {

  }

  salir() {
    this.auth.logout();
  }

}
