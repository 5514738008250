import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Convert, LoginRequest, LoginResponse } from '../../interfaces/login.interface';
import { URL_SERVICIOS } from '../../constants/constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: any = '';
  private url = URL_SERVICIOS;
  private params = new HttpParams();
  private options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  };
  private body = new URLSearchParams();

  constructor(private http: HttpClient, private router: Router) {
    this.cargarStorage();
  }

  login(login: LoginRequest) {
    const array = Object.entries(login);
    for (const obj of array) {
      this.body.set(obj[0], obj[1]);
    }
    const url = this.url + 'sesion.validar.php';
    return this.http.post<LoginResponse>(url, this.body.toString(), this.options).pipe(map(res => {
      if (res.estado === 200) {
        this.user = res.datos;
        localStorage.setItem('user', JSON.stringify(res.datos));
      }
      return res;
    }));
  }

  logout() {
    this.user = '';
    localStorage.removeItem('user');
    this.router.navigateByUrl('/public/auth/login');
  }

  cargarStorage() {
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user') || '');
    } else {
      this.user = '';
    }
  }

  estaLogueado() {
    return (!this.user) ? true : false;
  }
}
